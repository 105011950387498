// eslint-disable-next-line
import { cleanObject, isEmptyObject, isEmptyArray } from './Object-functions';

export const cleanArray = (array) =>
  array.reduce((cleaned, entry) => {
    if (entry && typeof entry === 'object' && !Array.isArray(entry)) {
      const newObject = cleanObject(entry);
      cleaned.push(newObject);
      return cleaned;
    }
    if (entry && typeof entry === 'object' && Array.isArray(entry)) {
      const newArray = cleanArray(entry);
      cleaned.push(newArray);
      return cleaned;
    }
    if (
      !(entry instanceof Date) &&
      (entry === '' ||
        entry === null ||
        entry === undefined ||
        isEmptyObject(entry) ||
        isEmptyArray(entry) ||
        Number.isNaN(entry))
    )
      return cleaned;
    cleaned.push(entry);
    return cleaned;
  }, []);
